import PropTypes from 'prop-types'

export const featuredImgProps = PropTypes.shape({
  desktop: PropTypes.object,
  tablet: PropTypes.object,
  mobile: PropTypes.object,
  blog: PropTypes.object,
  alt: PropTypes.string.isRequired,
  caption_MD: PropTypes.string,
})

export const heroProps = PropTypes.shape({
  header: PropTypes.string,
  subheader_MD: PropTypes.string,
  darken: PropTypes.number,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
})

export const frontmatterProps = {
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  date: PropTypes.string,
  // featuredImage: featuredImgProps,
  // hero: heroProps,
  templateKey: PropTypes.string,
}

export const markdownRemarkProps = (extraTypes) => ({
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        gitAuthorTime: PropTypes.string,
      }),
      frontmatter: PropTypes.shape(Object.assign(frontmatterProps, extraTypes)),
    }),
  }),
})
