import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { useDefaultArchiveImage } from '../hooks'

const RelatedPostTile = ({ slug, title, src, alt, isAmp }) => {
  const defaultImg = useDefaultArchiveImage()
  const img = !!src && !!alt ? { src, alt } : defaultImg
  return (
    <div className={`related-post col-6_tp-4_ds-3`}>
      <Link className="related-post-link" to={slug} rel="bookmark">
        {!!isAmp ? (
          <amp-img
            srcSet={img.src.childImageSharp.fluid}
            src={undefined}
            width={1}
            height={1}
            alt={img.alt}
            layout="responsive"
            className="related-post-img"
          />
        ) : (
          <Img
            className="related-post-img"
            fluid={img.src.childImageSharp.fluid}
            alt={img.alt}
          />
        )}
      </Link>
      <span className="related-post-title">{title}</span>
    </div>
  )
}

RelatedPostTile.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.object,
  alt: PropTypes.string,
  isAmp: PropTypes.bool,
}

export default RelatedPostTile
