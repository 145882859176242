import React, { createElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { useNavStructure } from '../hooks'
import Facebook from '../../static/core/svg/facebook.svg'
import Instagram from '../../static/core/svg/instagram.svg'
import Twitter from '../../static/core/svg/twitter.svg'
import Pinterest from '../../static/core/svg/pinterest.svg'

const Icons = { Facebook, Instagram, Twitter, Pinterest }

export default () => {
  const { social } = useNavStructure()
  return (
    <nav>
      <ul id="social-media-links" className="nav nav-level-1">
        {social.map(({ label, link }) => (
          <li key={uuidv4()} className="nav-item">
            <OutboundLink
              href={link}
              target="_blank"
              className="nav-item-link"
              rel="noopener noreferrer"
            >
              {createElement(Icons[label], { className: 'nav-item-icon' })}
            </OutboundLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}
