import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const InlineBackgroundImage = ({
  extraClasses,
  padding,
  justifyContent,
  imgSrc,
  imgAlt,
  imgStyles,
  overlayColor,
  children,
}) => {
  const basename = 'inline-bg-img'

  const fillAbsolute = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }

  const containerStyles = {
    padding: padding ? padding : 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: justifyContent ? justifyContent : 'center',
    ...fillAbsolute,
  }

  const image =
    typeof imgSrc === 'object' && imgSrc.childImageSharp ? (
      <Img
        className={`${basename}-image`}
        fluid={imgSrc.childImageSharp.fluid}
        alt={imgAlt}
        style={{ ...fillAbsolute, ...imgStyles }}
      />
    ) : (
      <img
        className={`${basename}-image`}
        alt={imgAlt}
        src={imgSrc}
        style={{ ...fillAbsolute, ...imgStyles }}
      />
    )

  return (
    <div className={basename} style={{ overflow: 'hidden' }}>
      <div className={`${basename}-underlay-wrapper`}>
        {image}
        <div
          className="is-overlay"
          style={{ backgroundColor: overlayColor || 'rgba(0,0,0,0.65)' }}
        />
      </div>
      <div
        className={`is-overlay ${extraClasses ? extraClasses : ''}`}
        style={containerStyles}
      >
        {children}
      </div>
    </div>
  )
}

InlineBackgroundImage.propTypes = {
  extraClasses: PropTypes.string,
  padding: PropTypes.string,
  justifyContent: PropTypes.string,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgStyles: PropTypes.object,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
}

export default InlineBackgroundImage
