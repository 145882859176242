import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useNavStructure, useCategories } from '../hooks'
import { removeTrailingSlash } from '../utils'

const NavItem = ({ link, label, menuChildren, level }) => (
  <li className={`nav-item ${menuChildren ? 'nav-has-children' : ''}`}>
    {!!menuChildren ? (
      <Fragment>
        <input
          type="checkbox"
          className="nav-toggle"
          aria-label={`Toggle ${label} submenu`}
        />
        <span className="nav-toggle-label">
          <Link to={removeTrailingSlash(link)} className="nav-item-link">
            {label}
          </Link>{' '}
          <span className="nav-toggle-plus-x">+</span>
        </span>
        <ul className={`nav nav-level-${level + 1}`}>
          <li className="nav-item hide-on-d">
            <Link to={link} className="nav-item-link">
              Overview
            </Link>
          </li>
          {menuChildren.map((child) => (
            <NavItem
              key={uuidv4()}
              link={removeTrailingSlash(child.link)}
              label={child.label}
              level={level + 1}
            />
          ))}
        </ul>
      </Fragment>
    ) : (
      <Link to={link} className="nav-item-link">
        {label}
      </Link>
    )}
  </li>
)

NavItem.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  menuChildren: PropTypes.array,
}

const NavMenu = () => {
  const { nav: navItems } = useNavStructure()
  const categories = useCategories()
  const catChildren = Object.keys(categories).map((key) => ({
    link: categories[key].slug,
    label: categories[key].label,
  }))
  return (
    <ul id="main-nav" className="main-nav nav nav-level-1">
      {navItems.map(({ label, link, children: kids, childRef }) => (
        <NavItem
          level={1}
          key={uuidv4()}
          label={label}
          link={link}
          menuChildren={
            childRef && childRef === 'categories'
              ? catChildren
              : Array.isArray(kids)
              ? kids
              : undefined
          }
        />
      ))}
    </ul>
  )
}

export default NavMenu
