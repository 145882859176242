import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query DefaultImageQuery {
        image: file(
          absolutePath: { regex: "/shutterstock_23455360.desktop.jpg/" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `,
  )
  query.image.alt = 'Golden Gate Bridge'
  return query.image
}
