export const schemaRefs = {
  owner: '#schemaStella',
  logo: '#schemaLogo',
  company: '#schemaSFDental',
}

const name = 'SF Dental'
const legalName = 'Stella Kim, DDS, Inc.'
const alternateName = ['SFDental']
const address = {
  '@type': 'PostalAddress',
  streetAddress: '800 Santiago Street, Suite B',
  addressLocality: 'San Francisco',
  addressRegion: 'Calfornia',
  postalCode: 94116,
  addressCountry: 'United States',
}
const geo = {
  '@type': 'GeoCoordinates',
  latitude: '37.745054',
  longitude: '-122.47618',
}
const hasMap =
  'https://www.google.com/maps/place/sfdental/@37.745021,-122.475949,17z/data=!3m1!4b1!4m2!3m1!1s0x808f7d91e365a3e1:0xa31d7f8c338953ab'
const email = 'office@sfdental.com'
const logo = {
  '@id': schemaRefs.logo,
}
const employee = schemaRefs.owner
const sameAs = [
  'https://www.facebook.com/sfdental',
  'https://twitter.com/sfdentalteam',
  'http://instagram.com/sfdental/',
  'https://www.pinterest.com/sfdental/',
  'https://www.yelp.com/biz/sf-dental-san-francisco-2',
  'https://www.zocdoc.com/dentist/stella-kim-dds-128619',
  'https://www.linkedin.com/company/sf-dental/',
]
const telephone = '+1-415-566-3305'
const faxNumber = '+1-415-566-3045'
const contactPoint = {
  '@type': 'ContactPoint',
  contactType: 'customer service',
  email,
  faxNumber,
  availableLanguage: ['English', 'Spanish', 'Korean'],
  telephone,
}

const openingHoursSpecification = [
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Thursday', 'Friday'],
    opens: '08:00',
    closes: '17:00',
  },
  {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: 'Wednesday',
    opens: '9:00',
    closes: '18:00',
  },
]

export const ownerSchema = {
  '@context': 'http://schema.org',
  '@type': 'Person',
  '@id': schemaRefs.owner,
  familyName: 'Kim',
  givenName: 'Stella',
  name: 'Stella Kim',
  jobTitle: ['Dentist', 'Chief Executive Officer'],
  worksFor: schemaRefs.company,
}

export const logoSchema = (origin) => ({
  '@context': 'http://schema.org/',
  '@type': 'ImageObject',
  '@id': schemaRefs.logo,
  url: origin + '/core/sfdental-logo.png',
  height: 90,
  width: 300,
})

const buildImage = (image) => ({
  '@context': 'http://schema.org/',
  '@type': 'ImageObject',
  url: image.url,
  height: image.height,
  width: image.width,
})

export const companySchema = ({ url, image, origin }) => ({
  '@context': 'http://schema.org/',
  '@type': 'Dentist',
  '@id': schemaRefs.company,
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': url,
  },
  name,
  legalName,
  employee,
  alternateName,
  url: origin,
  logo,
  address,
  geo,
  contactPoint,
  telephone,
  email,
  faxNumber,
  hasMap,
  openingHoursSpecification,
  priceRange: 'N/A',
  sameAs,
  image: buildImage(image),
})

export const publisherSchema = ({ image, origin }) => ({
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  '@id': schemaRefs.company,
  name,
  legalName,
  employee,
  alternateName,
  url: origin,
  logo,
  address,
  contactPoint,
  email,
  sameAs,
  image: buildImage(image),
})

function breadcrumb({ position, url, name }) {
  return {
    '@type': 'ListItem',
    position,
    item: {
      '@id': url,
      name,
    },
  }
}

export const breadcrumbSchema = (crumbs) => ({
  '@context': 'http://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: crumbs.map((crumb) => breadcrumb(crumb)),
})
