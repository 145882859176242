import {
  publisherSchema,
  ownerSchema,
  logoSchema,
  schemaRefs,
  breadcrumbSchema,
} from './main'

export const schemaArticleTemplate = ({
  headline,
  url,
  origin,
  description,
  image,
  datePublished,
  dateModified,
  breadcrumbs,
}) => {
  const articleSchema = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    headline,
    url,
    description,
    image: {
      '@type': 'ImageObject',
      url: image.url,
      width: image.width,
      height: image.height,
    },
    author: {
      '@id': schemaRefs.owner,
    },
    datePublished,
    dateModified,
    publisher: {
      '@id': schemaRefs.company,
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
  }

  return JSON.stringify(
    [
      publisherSchema({ url, image, origin }),
      articleSchema,
      ownerSchema,
      logoSchema(origin),
      breadcrumbs && breadcrumbs.length ? breadcrumbSchema(breadcrumbs) : null,
    ].filter((i) => i),
  )
}
