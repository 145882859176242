import moment from 'moment'

export const formatPhone = (original) => {
  let num = original.replace(/\D/g, '')
  if (num.substring(0, 1) === '1') {
    num = num.slice(1)
  }
  if (num.length <= 3) {
    return num
  } else if (num.length > 3 && num.length <= 6) {
    return num.substring(0, 3) + '-' + num.substring(3, 6)
  } else {
    return (
      num.substring(0, 3) +
      '-' +
      num.substring(3, 6) +
      '-' +
      num.substring(6, 10)
    )
  }
}

export const formatDOB = (original) => {
  let num = original.replace(/\D/g, '')
  switch (num.length) {
    case 0:
      return ''
    case 1:
      return parseInt(num) <= 1 ? num : `0${num}`
    case 2:
      return parseInt(num) <= 12 ? num : num.substring(0, 1)
    case 3:
      return parseInt(num.substring(2)) > 3
        ? `${num.substring(0, 2)} / 0${num.substring(2)}`
        : `${num.substring(0, 2)} / ${num.substring(2)}`
    case 4:
      return parseInt(num.substring(2)) <= 31
        ? `${num.substring(0, 2)} / ${num.substring(2)}`
        : `${num.substring(0, 2)} / ${num.substring(2, 3)}`
    case 5:
      return parseInt(num.substring(4)) <= 2
        ? `${num.substring(0, 2)} / ${num.substring(2, 4)} / ${num.substring(
            4,
          )}`
        : `${num.substring(0, 2)} / ${num.substring(2, 4)}`
    case 6:
      return parseInt(num.substring(4)) <= 20
        ? `${num.substring(0, 2)} / ${num.substring(2, 4)} / ${num.substring(
            4,
          )}`
        : `${num.substring(0, 2)} / ${num.substring(2, 4)} / ${num.substring(
            4,
            5,
          )}`
    default:
      return `${num.substring(0, 2)} / ${num.substring(2, 4)} / ${num.substring(
        4,
        8,
      )}`
  }
}

export function formatTelLink(phone) {
  let newPhone = phone.replace(/\D/g, '').toString()
  if (newPhone.substring(0, 1) !== '1') {
    newPhone = '1' + newPhone
  }
  return 'tel:+' + newPhone
}

export function getErrorMessage(data, response) {
  if (!!data && !!data.error && typeof data.error === 'string') {
    return data.error
  }
  if (!!data && !!data.error && !!data.error.message) {
    return data.error.message
  }
  if (!!data && !!data.error && !!data.error.msg) {
    return data.error.msg
  }
  if (!!data && !!data.error && !!data.error.error) {
    return data.error.error
  }
  return response.statusText
}

export function handleError(err) {
  console.error(err)
  return new Response(err)
}

export function is2xxStatus(status) {
  const s = !!status ? parseInt(status) : 0
  return s >= 200 && s < 300
}

export function titleCase(text) {
  return typeof text === 'string' && text.length
    ? text
        .trim()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : text
}

/**
 * Utility functions to help format user info before submission
 * @param {Object} newUserInfo
 * @param {Func} setFunc
 */

export function sanitizeFormValue(value, field) {
  switch (field) {
    case 'zip':
      return value.replace(/\D/g, '').substring(0, 5)
    case 'phone':
      return formatPhone(value)
    case 'dob':
      return formatDOB(value)
    default:
      return value
  }
}

export function findUrl(obj) {
  let result = null
  if (typeof obj === 'string' && obj.indexOf('https://') === 0) {
    return obj
  }
  if (typeof obj === 'object' && obj !== null) {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
      result = findUrl(obj[keys[i]])
      if (result) {
        break
      }
    }
  }
  return result
}

export function removeTrailingSlash(path) {
  if (path === '/') {
    return path
  }
  return path.slice(-1) === '/' ? path.slice(0, -1) : path
}

export function getValidDates(date, gitAuthorTime, gitCreatedTime) {
  const mDate =
    !!date && typeof date === 'string' && date.replace(/\D/g, '').length
      ? moment(date, 'YYYY-MM-DD')
      : null
  const mCreate = moment(gitCreatedTime)
  const mModified =
    !!gitAuthorTime && gitAuthorTime !== 'Invalid Date'
      ? moment(gitAuthorTime)
      : null

  const output = {
    date: !!mDate && mDate.isValid() ? mDate : mCreate,
  }
  output.dateModified =
    mModified && mModified.isValid() ? mModified : output.date
  return output
}
