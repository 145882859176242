import { useStaticQuery, graphql } from 'gatsby'

const labelMap = {
  faq: 'Frequently Asked Questions',
  invisalign: 'Invisalign',
  'teeth-whitening': 'Teeth Whitening',
  'oral-health': 'Oral Health',
  diet: 'Diet & Nutrition',
  pregnancy: 'Pregnancy',
  children: 'Children',
  seniors: 'Seniors',
}

export default () => {
  const query = useStaticQuery(
    graphql`
      query ListAllCategoriesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "archive" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                hero {
                  header
                }
              }
            }
          }
        }
      }
    `,
  )

  const output = {}
  query.allMarkdownRemark.edges.forEach(
    ({
      node: {
        fields: { slug },
        frontmatter: {
          hero: { header: title },
        },
      },
    }) => {
      const [, key] = slug.split('/').filter((i) => i)
      output[key] = { slug, title, label: labelMap[key] }
    },
  )
  return output
}
