import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'
import sfDentalLogo from '../../static/core/sfdental.svg'
import Hamburger from '../../static/core/svg/hamburger.svg'
import CloseX from '../../static/core/svg/closex.svg'
import sfDentalLogoWhite from '../../static/core/sfdental-white.svg'
import PropTypes from 'prop-types'
import NavMenu from './NavMenu'
import CtaNav from './CtaNav'
import InlineBackgroundImage from './InlineBackgroundImage'

const useHeaderBGImg = () =>
  useStaticQuery(
    graphql`
      query HeaderBGImgQuery {
        image: file(absolutePath: { regex: "/bg-header.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

const SiteHeader = ({ location }) => {
  const [ref, inView] = useInView()
  const [navOpen, setNavOpen] = useState(false)
  const [scroll, setScroll] = useState(false)
  const { image: bgImg } = useHeaderBGImg()

  function toggleNav() {
    setNavOpen(!navOpen)
  }

  useEffect(() => {
    function scrollListener() {
      setNavOpen(false)
      setScroll(true)
    }
    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [setNavOpen, setScroll])

  const headerClasses = [
    'header',
    !scroll ? '' : inView ? '' : 'scroll',
    navOpen ? 'nav-open' : '',
  ]
    .filter((x) => x)
    .join(' ')

  return (
    <header className={headerClasses} id="site-header">
      <InlineBackgroundImage imgSrc={bgImg} imgAlt="background texture">
        <div id="upper-header" className="wrap" ref={ref}>
          <Link to={'/'} className="logo-holder">
            <img
              src={sfDentalLogo}
              alt="SF Dental logo"
              className="logo color-logo"
            />
            <span className="logo-tagline hide-on-m">
              Modern Dentistry | Classic Values
            </span>
          </Link>
          <button className="nav-toggle-main hide-on-d" onClick={toggleNav}>
            <Hamburger className="nav-hamburger" />
            <CloseX className="nav-close-x" />
          </button>
        </div>
        <nav id="site-nav" role="navigation" className={`nav site-nav`}>
          <div id="main-nav-holder" className="main-nav-holder">
            <div id="white-logo-holder" className="hide-on-m hide-on-t">
              <Link to={'/'}>
                <img
                  src={sfDentalLogoWhite}
                  alt="SF Dental logo"
                  id="white-logo"
                />
              </Link>
            </div>
            <NavMenu />
            <CtaNav slug={location.slug} navId={'header'} />
          </div>
        </nav>
      </InlineBackgroundImage>
    </header>
  )
}

SiteHeader.propTypes = {
  location: PropTypes.object.isRequired,
}

export default SiteHeader
