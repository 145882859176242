import React, { Element } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import HTMLContent from './HTMLContent'
import { featuredImgProps } from '../proptypes'

const Hero = ({
  featuredImage,
  header,
  subheader_MD,
  darken,
  textColor,
  bgColor,
  isAmp,
  heroRef,
}) => {
  const heroClasses = [
    'hero',
    'panel',
    'panel-hero',
    !!featuredImage ? 'has-hero-img' : 'no-hero-img',
    bgColor ? `panel-${bgColor}` : '',
    !!textColor ? `text-${textColor}` : '',
  ]
    .filter((item) => item)
    .join(' ')

  const sources =
    !!featuredImage &&
    !!featuredImage.mobile &&
    !!featuredImage.tablet &&
    !!featuredImage.desktop
      ? [
          featuredImage.mobile.childImageSharp.fluid,
          {
            ...featuredImage.tablet.childImageSharp.fluid,
            media: `(min-width: 700px)`,
          },
          {
            ...featuredImage.desktop.childImageSharp.fluid,
            media: `(min-width: 1076px)`,
          },
        ]
      : !!featuredImage && !!featuredImage.blog
      ? [featuredImage.blog.childImageSharp.fluid]
      : null

  return (
    <section id="hero" className={heroClasses}>
      <div className="wrap">
        <div className="hero-content-holder">
          <div className="hero-content" ref={heroRef}>
            <h1
              className="hero-content-title"
              dangerouslySetInnerHTML={{ __html: header }}
            />
            {!!subheader_MD && (
              <HTMLContent
                content={subheader_MD}
                className="hero-content-subtitle"
              />
            )}
          </div>
        </div>
      </div>
      {!!featuredImage && (
        <div className="hero-img-holder">
          {!!isAmp ? (
            <amp-img
              srcSet={featuredImage.mobile.childImageSharp.fluid.srcSet}
              src={featuredImage.mobile.childImageSharp.fluid.src}
              width={
                featuredImage.mobile.childImageSharp.fluid.presentationWidth
              }
              height={
                featuredImage.mobile.childImageSharp.fluid.presentationHeight
              }
              alt={featuredImage.alt}
              layout="responsive"
            />
          ) : (
            <Img
              fluid={sources}
              alt={featuredImage.alt}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            />
          )}
          {!!darken && (
            <div
              className="hero-darken"
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: `rgba(0,0,0,${darken / 100})`,
              }}
            />
          )}
        </div>
      )}
      {!!featuredImage && !!featuredImage.caption_MD && (
        <HTMLContent
          className="photo-credit wrap"
          content={featuredImage.caption_MD}
        />
      )}
    </section>
  )
}

Hero.propTypes = {
  featuredImage: featuredImgProps,
  header: PropTypes.string,
  subheader_MD: PropTypes.string,
  darken: PropTypes.number,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  isAmp: PropTypes.bool,
  heroRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

export default Hero
