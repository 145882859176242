import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'gatsby'
import InlineBackgroundImage from '../InlineBackgroundImage'
import { removeTrailingSlash } from '../../utils'

const CategorySummary = ({ slug, title, alt, src, articles }) => (
  <div className="all-category-summary-pane">
    <div className="all-category-summary-img-holder">
      <InlineBackgroundImage
        extraClasses="all-category-summary-img"
        imgSrc={src}
        imgAlt={alt}
      >
        <Link to={slug} className="all-category-summary-img-link">
          {title}
        </Link>
      </InlineBackgroundImage>
    </div>
    {!!articles && articles.length && (
      <ul className="all-category-summary-listing nav panel-brown">
        {articles.map((article) => (
          <li className="all-category-summary-item nav-item" key={uuidv4()}>
            <Link
              to={removeTrailingSlash(article.slug)}
              className="all-category-summary-item-link nav-item-link"
            >
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
    )}
  </div>
)

CategorySummary.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  src: PropTypes.object.isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
}

export default CategorySummary
