import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import Chevron from '../../static/core/svg/chevron.svg'
import { scrollTo } from '../hooks'

const SubMenuLink = ({ menuLabel, anchor, className, setIsOpen }) => (
  <li className={`nav-item subnav-item ${!!className ? className : ''}`}>
    <a
      href={`#${anchor}`}
      rel="bookmark"
      className="nav-item-link subnav-item-link"
      onClick={(e) => {
        e.preventDefault()
        scrollTo(`#${anchor}`)
        setIsOpen(false)
      }}
    >
      {menuLabel}
    </a>
  </li>
)

SubMenuLink.propTypes = {
  menuLabel: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  className: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
}

const menuBarHeight = 46

const SubMenu = ({ items, heroTextInView }) => {
  const menuRef = useRef()
  const offsetTopRef = useRef()
  const [scroll, setScroll] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    function scrollListener() {
      if (!offsetTopRef.current) {
        offsetTopRef.current = menuRef.current.offsetTop
      }
      if (isOpen) {
        setIsOpen(false)
      }
      const scrollCheck =
        !!offsetTopRef.current &&
        window.scrollY > offsetTopRef.current - menuBarHeight
      if (scrollCheck && !scroll) {
        setScroll('scroll')
      } else if (!scrollCheck && scroll) {
        setScroll('')
      }
    }
    function resizeListener() {
      // offsetTopRef.current = menuRef.current.offsetTop
      setIsOpen(false)
    }
    window.addEventListener('scroll', scrollListener)
    window.addEventListener('resize', resizeListener)
    return () => {
      // cleanup memory leaks
      window.removeEventListener('scroll', scrollListener)
      window.removeEventListener('resize', resizeListener)
    }
  }, [scroll, setScroll, isOpen, setIsOpen])

  function toggleCheckbox() {
    setIsOpen(!isOpen)
  }

  const subnavClasses = [
    'subnav',
    scroll,
    !scroll ? '' : heroTextInView ? '' : 'text-scroll',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <div id="subnav" className={subnavClasses} ref={menuRef}>
      <div className="subnav-wrapper">
        <input
          type="checkbox"
          id="subnav-btn"
          className="subnav-btn"
          checked={!!isOpen}
          onChange={toggleCheckbox}
          aria-label="Toggle page submenu"
        />
        <label htmlFor="subnav-btn" className="subnav-label">
          ☰ Jump to topic… <Chevron className="subnav-icon" />{' '}
        </label>
        <ul className="subnav-nav nav">
          {[
            {
              menuLabel: 'Back to Top',
              anchor: 'container',
              className: 'go-to-top',
            },
            ...items,
          ].map((props) => (
            <SubMenuLink key={uuidv4()} {...props} setIsOpen={setIsOpen} />
          ))}
        </ul>
      </div>
    </div>
  )
}

SubMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      menuLabel: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired,
    }),
  ).isRequired,
  heroTextInView: PropTypes.bool,
}

export default SubMenu
