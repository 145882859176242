import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query MapImageQuery {
        image: file(absolutePath: { regex: "/sfdentalmap.jpg$/" }) {
          childImageSharp {
            fluid(maxWidth: 569) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `,
  )
  query.image.alt = 'Map to SF Dental'
  return query.image
}
