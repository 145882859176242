import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { featuredImgProps } from '../proptypes'
import Fonts from './Fonts'
import {
  useImageDimensions,
  useDefaultImage,
  useStructuredData,
  useCategories,
} from '../hooks'
import { removeTrailingSlash } from '../utils'
import { graphql, useStaticQuery } from 'gatsby'

function buildBreadcrumbs(url, name, categories) {
  const [protocol, base] = url.split('//').filter((i) => i)
  const [origin, page, category, article] = base.split('/').filter((i) => i)
  if (page !== 'ask-a-dentist' || !category) {
    return null
  }
  const crumbs = [
    {
      position: 1,
      url: `${protocol}//${origin}/${page}`,
      name: 'Ask a Dentist',
    },
    {
      position: 2,
      url: `${protocol}//${origin}/${page}/${category}`,
      name: categories[category].label,
    },
  ]
  if (article) {
    crumbs.push({
      position: 3,
      url,
      name,
    })
  }

  return crumbs
}

function useTwitterHandle() {
  const query = useStaticQuery(
    graphql`
      query QueryTwitterHandle {
        site {
          siteMetadata {
            twitterHandle
          }
        }
      }
    `,
  )
  return query.site.siteMetadata.twitterHandle
}

const SEO = ({
  title,
  niceTitle,
  description,
  featuredImage,
  slug,
  date,
  dateModified,
  templateKey,
  location,
}) => {
  const imageDimensions = useImageDimensions()
  const defaultImg = useDefaultImage()
  const categories = useCategories()
  const twitterHandle = useTwitterHandle()

  const schemaData = {
    templateKey,
    headline: title,
    description: description,
    url: removeTrailingSlash(`${location.origin}${slug}`),
    origin: removeTrailingSlash(location.origin),
    image: {
      url:
        featuredImage && featuredImage.desktop
          ? location.origin + featuredImage.desktop.childImageSharp.fluid.src
          : defaultImg.childImageSharp.fluid.src,
      width: imageDimensions.hero.desktop.width,
      height: imageDimensions.hero.desktop.height,
    },
    pageType:
      templateKey && templateKey.indexOf('post') !== -1
        ? 'article'
        : 'business.business',
    datePublished: date,
    dateModified,
  }
  schemaData.breadcrumbs = buildBreadcrumbs(
    schemaData.url,
    niceTitle,
    categories,
  )
  const imgParts = schemaData.image.url.split('/')
  schemaData.image.url = location.origin + '/img/' + imgParts.pop()
  const ldjson = useStructuredData(schemaData)
  const titleTag =
    slug === '/'
      ? title
      : title.indexOf('| SF Dental') === -1
      ? title + ' | SF Dental'
      : title
  const robots =
    process.env.GATSBY_CMS_BRANCH === 'master'
      ? 'max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      : 'noindex, nofollow'

  return (
    <Fragment>
      <Fonts />
      <Helmet>
        <html lang="en" />
        <title>{titleTag}</title>
        <meta name="robots" content={robots} />
        <meta name="description" content={description} />
        <meta name="msapplication-TileColor" content="#F1E8E1" />
        <meta name="theme-color" content="#F1E8E1" />
        <meta property="og:type" content={schemaData.pageType} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={schemaData.url} />
        <meta property="og:image" content={schemaData.image.url} />
        {schemaData.pageType === 'article' && (
          <meta property="article:author" content="Stella Kim" />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={schemaData.image.url} />
        <script type="application/ld+json">{ldjson}</script>
      </Helmet>
    </Fragment>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  niceTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  dateModified: PropTypes.string.isRequired,
  templateKey: PropTypes.string.isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
  }).isRequired,
}

export default SEO
