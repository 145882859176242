import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'
import Panel from './Panel'

function buildColumnClasses(cols) {
  const [m, t, d] = cols
  return [
    `col-${m}_tp-${t}_ds-${d}`,
    `col-${m === 12 ? 12 : 12 - m}_tp-${t === 12 ? 12 : 12 - t}_ds-${
      d === 12 ? 12 : 12 - d
    }`,
  ]
}

const ImageDescriptionPanel = (props) => {
  const {
    content_MD,
    colImg: { colWidth, alignment, src, alt, classes },
    inlineImages,
  } = props
  const panelProps = {
    panelType: 'image_description',
    ...props,
  }

  const [imgColClass, txtColClass] = buildColumnClasses(colWidth)

  return (
    <Panel {...panelProps}>
      <div className={alignment === 'left' ? 'grid-reverse' : 'grid'}>
        <HTMLContent
          content={content_MD}
          className={`row-copy ${txtColClass}`}
          inlineImages={inlineImages}
        />
        <div className={`row-img_holder ${imgColClass}`}>
          <Img
            fluid={src.childImageSharp.fluid}
            alt={alt}
            className={classes}
          />
        </div>
      </div>
    </Panel>
  )
}

ImageDescriptionPanel.propTypes = {
  align: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  content_MD: PropTypes.string,
  inlineImages: PropTypes.array,
}

export default ImageDescriptionPanel
