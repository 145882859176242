import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Sprite from './Sprite'
import Footer from './Footer'
import SEO from './SEO'
import SiteHeader from './SiteHeader'
import { useSeoProps } from '../hooks'

import '../style/gridlex.scss'
import '../style/all.scss'

const Layout = ({ location, data, isAmp, children }) => {
  const seoProps = useSeoProps(data)
  return (
    <Fragment>
      <SEO {...seoProps} location={location} />
      <Sprite />
      <div id="container">
        <SiteHeader location={location} />
        {children}
        <Footer location={location} isAmp={isAmp} />
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isAmp: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
