import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const useHeadshot = () => {
  const query = useStaticQuery(
    graphql`
      query DocAttributionQuery {
        src: file(absolutePath: { regex: "/dr-stella-kim-headshot.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `,
  )
  const image = {
    src: query.src,
    alt: 'San Francisco dentist Dr. Stella Kim of SF Dental',
  }
  return image
}

const DocAttribution = ({ isAmp }) => {
  const img = useHeadshot()
  return (
    <div className="doc-attribution">
      <div className="doc-attribution-img">
        {!!isAmp ? (
          <amp-img
            srcSet={img.src.childImageSharp.fluid.srcSet}
            src={img.src.childImageSharp.fluid.src}
            width={img.src.childImageSharp.fluid.presentationWidth}
            height={img.src.childImageSharp.fluid.presentationHeight}
            alt={img.alt}
            layout="responsive"
          />
        ) : (
          <Img fluid={img.src.childImageSharp.fluid} alt={img.alt} />
        )}
      </div>
      <div className="doc-attribution-blurb">
        <h3>About the author, Dr. Stella Kim</h3>
        <p>
          The materials on this page were prepared and medically reviewed by Dr.
          Stella Kim, DDS, a San Francisco dentist and a graduate of the UCSF
          School of Dentistry.
        </p>
      </div>
    </div>
  )
}

DocAttribution.propTypes = {
  isAmp: PropTypes.bool,
}

export default DocAttribution
