import React from 'react'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'
import Panel from './Panel'

const FullWidthPanel = (props) => {
  const { content_MD, inlineImages } = props
  const panelProps = {
    panelType: 'full_width',
    ...props,
  }

  return (
    <Panel {...panelProps}>
      {!!content_MD && (
        <HTMLContent
          content={content_MD}
          className="panel-text"
          inlineImages={inlineImages}
        />
      )}
    </Panel>
  )
}

FullWidthPanel.propTypes = {
  align: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  content_MD: PropTypes.string,
  inlineImages: PropTypes.array,
}

export default FullWidthPanel
