import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Panel from './Panel'

const BannerPanel = (props) => {
  const { bannerImg } = props
  const panelProps = {
    panelType: 'banner',
    ...props,
  }
  return (
    <Panel {...panelProps}>
      <Img
        fluid={bannerImg.src.childImageSharp.fluid}
        alt={bannerImg.alt}
        // style={{ position: 'static' }}
      />
    </Panel>
  )
}

BannerPanel.propTypes = {
  bannerImg: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
}

export default BannerPanel
