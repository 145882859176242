import { schemaPageTemplate } from './page'
import { schemaArticleTemplate } from './article'
import { schemaArchiveTemplate } from './archive'

export default function (data) {
  const isPage = data.templateKey.indexOf('page') !== -1
  const isArticle = data.templateKey.indexOf('post') !== -1
  const isArchive = data.templateKey.indexOf('archive') !== -1

  if (isPage) {
    return schemaPageTemplate(data)
  }
  if (isArticle) {
    return schemaArticleTemplate(data)
  }
  if (isArchive) {
    return schemaArchiveTemplate(data)
  }
}
