import React from 'react'
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Img from 'gatsby-image'
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'gatsby'
import { useNavStructure, useMapImage } from '../hooks'
import SocialIcons from './SocialIcons'
import MapPin from '../../static/core/svg/mappin.svg'
import Clock from '../../static/core/svg/clock.svg'
import Phone from '../../static/core/svg/phone.svg'
import PropTypes from 'prop-types'

const Footer = ({ location, isAmp }) => {
  const { nav: navItems } = useNavStructure()
  const mapImg = useMapImage()
  const date = new Date()

  // console.log(mapImg)

  return (
    <footer className="footer" id="footer">
      <div id="upper-footer" className="panel-light-blue">
        <div className="wrap grid-spaceBetween">
          <div id="footer-map" className="col-12_tp-6_tl-5_ds-4">
            <div className="footer-label">
              <MapPin />
              <h3>Directions to SF Dental</h3>
            </div>
            <div className="address">
              <span>800 Santiago St. Suite B</span>, <span>San Francisco</span>,{' '}
              <span>CA</span> <span>94116</span>
            </div>
            <span>
              <a
                href="https://www.google.com/maps/place/sfdental/@37.745021,-122.475949,17z/data=!3m1!4b1!4m2!3m1!1s0x808f7d91e365a3e1:0xa31d7f8c338953ab"
                title="Google Map to SF Dental"
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                {!!isAmp ? (
                  <amp-img
                    srcSet={mapImg.childImageSharp.fluid.srcSet}
                    src={mapImg.childImageSharp.fluid.src}
                    width={mapImg.childImageSharp.fluid.presentationWidth}
                    height={mapImg.childImageSharp.fluid.presentationHeight}
                    alt={mapImg.alt}
                    layout="responsive"
                  />
                ) : (
                  <Img fluid={mapImg.childImageSharp.fluid} alt={mapImg.alt} />
                )}
              </a>
            </span>
          </div>

          <div className="col-12_tp-6_tl-7_ds-8 grid" id="hours-contact">
            <div id="office-hours" className="col-12_tp-12_tl-6">
              <div className="footer-label">
                <Clock />
                <h3>Office Hours</h3>
              </div>
              <table id="office-hours-table">
                <tbody>
                  <tr>
                    <th scope="row">Mon</th>
                    <td>8:00 am &ndash; 5:00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">Tue</th>
                    <td>8:00 am &ndash; 5:00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">Wed</th>
                    <td>9:00 am &ndash; 6:00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thu</th>
                    <td>8:00 am &ndash; 5:00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">Fri</th>
                    <td>8:00 am &ndash; 5:00 pm</td>
                  </tr>
                  <tr>
                    <th scope="row">Sat</th>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <th scope="row">Sun</th>
                    <td>Closed</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div id="footer-contact" className="col-12_tp-12_tl-6">
              <div className="footer-label">
                <Phone /> <h3>Contact Us</h3>
              </div>
              <ul className="nav footer-contact-nav">
                <li className="nav-item">
                  <a
                    className="nav-item-link"
                    href="tel:+14155663305"
                    // onClick={(e) => {
                    //   if (typeof window !== 'undefined') {
                    //     function hitCallback() {
                    //       window.location = e.target.href
                    //     }
                    //     e.preventDefault()
                    //     trackCustomEvent({
                    //       category: 'Phone',
                    //       action: 'tel clicked',
                    //       label: `${location.href}#footer`,
                    //       hitCallback,
                    //     })
                    //   }
                    // }}
                  >
                    <strong>Phone:</strong>{' '}
                    <span className="link-text">415-566-3305</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-item-link" href="fax:+14155663045">
                    <strong>Fax:</strong>{' '}
                    <span className="link-text">415-566-3045</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-item-link"
                    href="mailto:office@sfdental.com"
                    // onClick={(e) => {
                    //   if (typeof window !== 'undefined') {
                    //     function hitCallback() {
                    //       window.location = e.target.href
                    //     }
                    //     e.preventDefault()
                    //     trackCustomEvent({
                    //       category: 'Email',
                    //       action: 'mailto clicked',
                    //       label: `${location.href}#footer`,
                    //       hitCallback,
                    //     })
                    //   }
                    // }}
                  >
                    <strong>Email:</strong>{' '}
                    <span className="link-text">office@sfdental.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="lower-footer" className="panel-black">
        <div className="col-12 wrap">
          <nav>
            <ul id="footer-nav" className="nav nav-level-1">
              {navItems.map(({ link, label }) => (
                <li key={uuidv4()} className="nav-item">
                  <Link to={link} className="nav-item-link">
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <SocialIcons />
          <p className="source-org copyright">
            &copy; {date.getFullYear()} Stella Kim DDS, Inc. All rights
            reserved. Read our{' '}
            <Link to="/privacy-policy" rel="nofollow">
              Privacy Policy and CC Credits
            </Link>
            .
          </p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Footer
