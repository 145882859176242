import { companySchema, ownerSchema, logoSchema, schemaRefs } from './main'

export const schemaPageTemplate = ({
  headline,
  url,
  origin,
  description,
  image,
  pageType,
}) => {
  const pageSchema = {
    '@context': 'http://schema.org',
    '@type': pageType || 'WebPage',
    headline,
    url,
    description,
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: image.url,
      width: image.width,
      height: image.height,
    },
    author: {
      '@id': schemaRefs.owner,
    },
    copyrightHolder: {
      '@id': schemaRefs.company,
    },
  }

  return JSON.stringify([
    companySchema({ url, image, origin }),
    pageSchema,
    ownerSchema,
    logoSchema(origin),
  ])
}
