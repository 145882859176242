import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query QueryNavStructure {
        site {
          siteMetadata {
            nav {
              label
              link
              children {
                label
                link
              }
              childRef
            }
            social {
              label
              link
            }
          }
        }
      }
    `,
  )
  return query.site.siteMetadata
}
