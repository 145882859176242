import React from 'react'
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Phone from '../../static/core/svg/phone.svg'
import Calendar from '../../static/core/svg/calendar.svg'

const CtaNav = ({ slug, navId }) => (
  <ul id={`cta-nav-${navId}`} className="nav cta-nav">
    <li className="nav-item cta-nav-item">
      <a
        href="tel:+14155663305"
        className="nav-item-link cta-nav-item-link"
        // onClick={(e) => {
        //   if (typeof window !== 'undefined') {
        //     function hitCallback() {
        //       window.location = e.target.href
        //     }
        //     e.preventDefault()
        //     trackCustomEvent({
        //       category: 'Phone',
        //       action: 'tel clicked',
        //       label: `${slug}#${navId}`,
        //       hitCallback,
        //     })
        //   }
        // }}
      >
        <Phone className="cta-nav-icon" />{' '}
        <span className="cta-nav-item-link-text">415-566-3305</span>
      </a>
    </li>
    <li className="nav-item cta-nav-item">
      <Link to="/book-online" className="nav-item-link cta-nav-item-link">
        <Calendar className="cta-nav-icon" />{' '}
        <span className="cta-nav-item-link-text">Book Online</span>
      </Link>
    </li>
  </ul>
)

CtaNav.propTypes = {
  slug: PropTypes.string.isRequired,
  navId: PropTypes.string.isRequired,
}

export default CtaNav
