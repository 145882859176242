import React from 'react'
import PropTypes from 'prop-types'

const DividerPanel = ({ padding }) => {
  const wrapperStyles = {}
  if (padding && Object.keys(padding).length) {
    Object.keys(padding).forEach((key) => {
      const Key = key[0].toUpperCase() + key.substring(1)
      wrapperStyles[`padding${Key}`] = padding[key]
    })
  }
  return (
    <div className="panel panel-divider" style={wrapperStyles}>
      <div className="wrap">
        <hr />
      </div>
    </div>
  )
}

DividerPanel.propTypes = {
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
}

export default DividerPanel
