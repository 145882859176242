import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { useInterval } from '../../hooks'
import Panel from './Panel'
import Chevron from '../../../static/core/svg/chevron.svg'

const CarouselSlide = ({ i, active, quote, attribution }) => (
  <div className={`panel-carousel-slide ${i === active ? 'active' : ''}`}>
    <div className="panel-carousel-quote">
      {quote}
      {!!attribution && (
        <div className="panel-carousel-attribution">
          <br />
          &mdash; {attribution}
        </div>
      )}
    </div>
  </div>
)

CarouselSlide.propTypes = {
  i: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  quote: PropTypes.string.isRequired,
  attribution: PropTypes.string,
}

const delay = 4000

const CarouselPanel = (props) => {
  const { slides } = props
  const panelProps = {
    panelType: 'carousel',
    ...props,
  }
  const [active, setActive] = useState(0)
  const [isRunning, setIsRunning] = useState(true)

  useEffect(() => {
    let timer
    if (!isRunning) {
      timer = setTimeout(() => {
        setIsRunning(true)
      }, delay)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isRunning, setIsRunning])

  useInterval(
    () => {
      setActive(active === slides.length - 1 ? 0 : active + 1)
    },
    isRunning ? delay : null,
  )

  function next() {
    setIsRunning(false)
    setActive(active === slides.length - 1 ? 0 : active + 1)
  }

  function prev() {
    setIsRunning(false)
    setActive(active < 1 ? slides.length - 1 : active - 1)
  }

  return (
    <Panel {...panelProps}>
      {!!slides && slides.length && (
        <div className="grid-spaceBetween-middle-noGutter">
          <div className="col-2">
            <div
              className="circle circle-left"
              onClick={prev}
              onKeyDown={prev}
              tabIndex={-1}
              role="button"
            >
              <Chevron className="chevron chevron-left" />
            </div>
          </div>
          <div className="panel-carousel-holder col-8">
            {slides.map(({ quote, attribution }, i) => (
              <CarouselSlide
                key={uuidv4()}
                i={i}
                active={active}
                quote={quote}
                attribution={attribution}
              />
            ))}
          </div>
          <div className="col-2">
            <div
              className="circle circle-right"
              onClick={next}
              onKeyDown={prev}
              tabIndex={-1}
              role="button"
            >
              <Chevron className="chevron chevron-right" />
            </div>
          </div>
        </div>
      )}
    </Panel>
  )
}

CarouselPanel.propTypes = {
  align: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string.isRequired,
      attribution: PropTypes.string,
    }),
  ),
}

export default CarouselPanel
