import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import Img from 'gatsby-image'
import HTMLContent from '../HTMLContent'

const Panel = ({
  panelType,
  align,
  bgColor,
  bgImg,
  textColor,
  title,
  subtitle_MD,
  extraClasses,
  children,
  padding,
  anchor,
  inlineImages,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    // rootMargin: '-50px 0px',
  })

  const extraWrapperClasses =
    extraClasses && extraClasses.length ? extraClasses : []
  const wrapperClasses = [
    'panel',
    'panel-main',
    `panel-${panelType}`,
    bgColor ? `panel-${bgColor}` : `panel-white`,
    bgImg ? `bg-darken panel-bg-img` : '',
    textColor ? `text-${textColor}` : '',
    align ? `align${align}` : '',
    inView ? 'visible' : '',
    ...extraWrapperClasses,
  ]
    .filter((i) => i)
    .join(' ')
  const innerStyles = {}
  if (padding && Object.keys(padding).length) {
    Object.keys(padding).forEach((key) => {
      const Key = key[0].toUpperCase() + key.substring(1)
      innerStyles[`padding${Key}`] = padding[key]
    })
  }

  const contentStyles = {
    position: !!bgImg ? 'relative' : undefined,
  }

  return (
    <section id={anchor} className={wrapperClasses} ref={ref}>
      {!!bgImg && !!bgImg.src && (
        <div className="bg-darken-target">
          <Img
            fluid={bgImg.src.childImageSharp.fluid}
            alt={bgImg.alt}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            imgStyle={{ objectPosition: 'center 10%' }}
          />
        </div>
      )}
      <div className="panel-content wrap" style={contentStyles}>
        <div className="panel-content-inner" style={innerStyles}>
          {!!title && (
            <div className="panel-title">
              <h2>{title}</h2>
            </div>
          )}
          {!!subtitle_MD && (
            <HTMLContent
              className={`panel-subtitle aligncenter`}
              content={subtitle_MD}
              inlineImages={inlineImages}
            />
          )}
          {children}
        </div>
      </div>{' '}
    </section>
  )
}

Panel.propTypes = {
  panelType: PropTypes.string.isRequired,
  align: PropTypes.string,
  bgColor: PropTypes.string,
  bgImg: PropTypes.shape({
    src: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  textColor: PropTypes.string,
  title: PropTypes.string,
  subtitle_MD: PropTypes.string,
  extraClasses: PropTypes.array,
  padding: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  anchor: PropTypes.string,
  inlineImages: PropTypes.array,
  children: PropTypes.node.isRequired,
}

export default Panel
