import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { useDefaultArchiveImage } from '../../hooks'

const ArticleSummary = ({ slug, title, src, alt, date, excerpt }) => {
  const defaultImg = useDefaultArchiveImage()
  const img = !!src && !!alt ? { src, alt } : defaultImg
  return (
    <article className={`cf category-summary-article grid wrap`}>
      <Link
        className="category-summary-article-img-link col-12_tp-3_ds-2"
        to={slug}
        rel="bookmark"
      >
        <Img
          className="category-summary-article-img"
          fluid={img.src.childImageSharp.fluid}
          alt={img.alt}
        />
        <h2 className="category-summary-article-title hide-on-t hide-on-d">
          {title}
        </h2>
      </Link>
      <div className={`category-summary-article-content cf col-12_tp-9_ds-10`}>
        <Link to={slug} className="category-summary-article-link hide-on-m">
          <h2 className="category-summary-article-title">{title}</h2>
        </Link>

        <span className="byline category-summary-article-meta">
          Last Updated:{' '}
          <time
            className="updated entry-time"
            dateTime={date.format('YYYY-MM-DD')}
          >
            {date.format('MMM D, YYYY')}
          </time>
        </span>
        <p className="category-summary-article-excerpt">
          {excerpt}{' '}
          <Link to={slug} className="category-summary-article-link">
            Read More &raquo;
          </Link>
        </p>
      </div>
    </article>
  )
}

ArticleSummary.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.object,
  alt: PropTypes.string,
  date: PropTypes.instanceOf(moment().constructor).isRequired,
  excerpt: PropTypes.string.isRequired,
}

export default ArticleSummary
