import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// import { v4 as uuidv4 } from 'uuid'
import { Link } from 'gatsby'
import { useCategories } from '../hooks'

const Breadcrumbs = ({ slug, title }) => {
  const categories = useCategories()
  const [base, category, article] = slug.split('/').filter((i) => i)
  return (
    <div className="breadcrumb-holder">
      <div className="wrap cf col-12" id="breadcrumbs">
        <Link to={`/${base}`}>Ask a Dentist</Link>
        <span style={{ padding: '0 0.5em' }}>/</span>
        {!!article ? (
          <Fragment>
            <Link to={`/${base}/${category}`}>
              {categories[category].title}
            </Link>
            <span style={{ padding: '0 0.5em' }}>/</span>
            <span>{title}</span>
          </Fragment>
        ) : (
          <span>{title}</span>
        )}
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Breadcrumbs
