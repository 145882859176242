import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query DefaultArchiveImageQuery {
        src: file(absolutePath: { regex: "/shutterstock_23455360.blog.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 420, maxHeight: 420, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `,
  )
  const image = {
    src: query.src,
    alt: 'Golden Gate Bridge',
  }
  return image
}
