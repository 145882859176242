import React from 'react'
// import SvgSprite from 'react-svg-sprite'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import calendar from '!!raw-loader!../../static/core/sprite/calendar.svg'
import yelp from '!!raw-loader!../../static/core/sprite/yelp.svg'
import zocdoc from '!!raw-loader!../../static/core/sprite/zocdoc.svg'
import googlereviews from '!!raw-loader!../../static/core/sprite/googlereviews.svg'

function attribute(name, value, svg) {
  const pattern = new RegExp(`${name}=(?:"|')([^("|')]*)(?:"|')`)

  if (!value) {
    let svgOpenTag = svg.startsWith('<svg') ? svg.match(/<svg[^>]*>/)[0] : null
    value =
      svgOpenTag && pattern.test(svgOpenTag)
        ? svgOpenTag.match(pattern)[1]
        : null
  }

  return value
}

function extractChildren(svg) {
  return { __html: svg.replace(/<svg[^>]*>|<\/svg>/g, '') } // remove svg tags
}

const Symbol = ({ symbol: { svg, name, viewBox, className, ...other } }) => (
  <symbol
    {...other}
    key={name}
    id={attribute('id', name, svg)}
    viewBox={attribute('viewBox', viewBox, svg)}
    className={attribute('class', className, svg)}
    dangerouslySetInnerHTML={extractChildren(svg)}
  />
)

Symbol.propTypes = {
  symbol: PropTypes.shape({
    svg: PropTypes.string.isRequired,
    name: PropTypes.string,
    viewBox: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
}

const SvgSprite = ({ symbols, style, ...other }) => (
  <svg {...other} style={style}>
    {symbols.map((symbol) => {
      return <Symbol key={uuidv4()} symbol={symbol} />
    })}
  </svg>
)

SvgSprite.propTypes = {
  symbols: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  style: PropTypes.object,
}

SvgSprite.defaultProps = {
  style: { display: 'none' },
}

const Sprite = () => (
  <SvgSprite
    symbols={[
      {
        name: 'calendar',
        svg: calendar,
      },
      {
        name: 'yelp',
        svg: yelp,
      },
      {
        name: 'zocdoc',
        svg: zocdoc,
      },
      {
        name: 'googlereviews',
        svg: googlereviews,
      },
    ]}
  />
)

export default Sprite
